import React from 'react';
import Button from '../Button';

const link = name => (name=='/index'? '/' : `/${name}`);

const ButtonSelector =props=> {
    // const input = props.linkTo;
    const data = props.data;

    if(data.linkTo === 'Form') {
      return (
        <Button {...props} target='blank' variant="contained" disableElevation size="large" color='primary'  href={`/${data.linkTo}`}>
          {data.buttonLabel}
      </Button>
      )
    }
   else {
    if(data.pageexternalpdf && data.pageexternalpdf.pdfLink){
      return(
      <Button {...props} target="blank" variant="contained" disableElevation size="large" color='primary' href={data.pageexternalpdf.pdfLink} >
        {data.buttonLabel}
      </Button>
      )
    }
    return(
      <Button {...props} variant="contained" disableElevation size="large" color='primary' to={link(data.pageexternalpdf.name)} >
        {data.buttonLabel} 
      </Button>
    )
  }
}

export default ButtonSelector;