import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import componentFinder from '../util/componentFinder';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import ButtonSelector from '../../components/ButtonSelector';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = props =>
  makeStyles(theme => ({
    buttonContained: {
      fontSize: '1.1875rem',
      fontWeight: 600,
      borderRadius:'0px',
      border: 'solid #B90605',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
      },
    },
    buttonOutlined: {
      fontSize: '1.1875rem',
      fontWeight: 600,
      borderRadius:'0px',
      border: 'solid 4px #B90605',
      '&:hover': {
        borderColor: theme.palette.primary.light,
      },
    },
}));

const CTAButton = (props) => {
  const data = props.data[props.componentId]
    const classes = useStyles({ ...props, data })();

    return(
    <Box py={2}>
        <Container maxWidth="lg">
            <Box display='flex' alignItems="center" justifyContent="center">
                <ButtonSelector disableElevation className = {classes.buttonContained} data={data}/>
            </Box>
        </Container>
    </Box>
    )
}

export default CTAButton;
